import React from 'react';
import Logo from '../../resources/images/header/load.png';
import './styles.scss';

export default function Loading() {
  return (
    <div className='loader'>
      <img src={Logo} alt='logo' style={{ width: '130px' }} />
      <div className='outer'></div>
      <div className='middle'></div>
      <div className='inner'></div>
    </div>
  );
}
